import revive_payload_client_btcDWSZkmF from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5ltr5pliaq3eku56qi5o2ansju/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7SMmBMGkmW from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5ltr5pliaq3eku56qi5o2ansju/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_2TQsccUFds from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5ltr5pliaq3eku56qi5o2ansju/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_DxGcGGXs0g from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5ltr5pliaq3eku56qi5o2ansju/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_yTfI6jOeu3 from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5ltr5pliaq3eku56qi5o2ansju/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_otTWYhQrx3 from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5ltr5pliaq3eku56qi5o2ansju/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_AFMzx5pPIK from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5ltr5pliaq3eku56qi5o2ansju/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_W2jegTghvj from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5ltr5pliaq3eku56qi5o2ansju/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/workspace/kody/hi3/.nuxt/components.plugin.mjs";
import prefetch_client_jxzmOTqCKd from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5ltr5pliaq3eku56qi5o2ansju/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  revive_payload_client_btcDWSZkmF,
  unhead_7SMmBMGkmW,
  router_2TQsccUFds,
  payload_client_DxGcGGXs0g,
  navigation_repaint_client_yTfI6jOeu3,
  check_outdated_build_client_otTWYhQrx3,
  view_transitions_client_AFMzx5pPIK,
  chunk_reload_client_W2jegTghvj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_jxzmOTqCKd
]