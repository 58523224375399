export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"author","content":"Kody"},{"name":"twitter:card","content":"summary"},{"name":"twitter:dnt","content":"on"},{"name":"twitter:site","content":"@0kody"},{"property":"og:locale","content":"fr_FR"},{"property":"og:site_name","content":"HI3"},{"name":"apple-mobile-web-app-title","content":"HI3"},{"name":"application-name","content":"HI3"},{"name":"msapplication-Config","content":"/browserconfig.xml"},{"name":"msapplication-TileColor","content":"#282f3f"},{"name":"theme-color","content":"#282f3f"},{"name":"format-detection","content":"telephone=no"}],"link":[{"rel":"preload","as":"image","type":"image/png","href":"/img/bg/media.jpg"},{"rel":"preload","as":"image","type":"image/png","href":"/img/bg/news.jpg"},{"rel":"preload","as":"image","type":"image/png","href":"/img/bg/chars.avif"},{"rel":"apple-touch-icon","sizes":"120x120","href":"/img/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/img/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/img/favicon-16x16.png"},{"rel":"manifest","href":"/site.webmanifest"},{"rel":"mask-icon","color":"#282f3f","href":"/img/safari-pinned-tab.svg"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"fr"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = true

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = true

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"